import { useLayoutEffect, useRef, useState } from "react";

type EventCallbackInput<T> = T | ((prevState: T, bool?: boolean) => T);
type EventCallback<T> = (newState: EventCallbackInput<T>, isInCallback?: boolean) => void;

const globalState: Map<string, any> = new Map();
const globalStateSubscribers: Map<string, Set<EventCallback<any>>> = new Map();

/**
 * 
 * @param stateKey Key to identify the global value used
 * @param defaultValue Value to use if no global state is present
 * @returns 
 */
export default function useGlobalState<T>(stateKey: string, defaultValue?: T) {
    if (defaultValue !== undefined && !globalState.has(stateKey)) {
        globalState.set(stateKey, defaultValue);
    }
    const [internalState, setInternalState] = useState<T>(globalState.get(stateKey) ?? defaultValue);
    const callbackRef = useRef<EventCallback<T>>(updateGlobalState);

    useLayoutEffect(() => {
        if (!globalState.has(stateKey)) {
            return;
        }
        // Trigger if the global state ever changes
        setInternalState(globalState.get(stateKey));
    }, [globalState.get(stateKey), stateKey]);

    useLayoutEffect(() => {
        callbackRef.current = updateGlobalState;
        if (!globalStateSubscribers.has(stateKey)) {
            globalStateSubscribers.set(stateKey, new Set());
        }

        globalStateSubscribers.get(stateKey)?.add(callbackRef.current);
        return () => {
            // Remove self from globalStateSubscribers
            globalStateSubscribers.get(stateKey)?.delete(callbackRef.current);
        }
    }, [updateGlobalState, stateKey]);

    function updateGlobalState(newState: EventCallbackInput<T>, isInCallback?: boolean) {
        const newGlobalState = newState instanceof Function ? newState(internalState) : newState;
        setInternalState(newGlobalState);

        if (isInCallback) {
            return;
        }

        const callbacks = globalStateSubscribers.get(stateKey);
        if (!callbacks) {
            return;
        }
        // Update all subscribers
        for (const callback of callbacks) {
            if (callback === callbackRef.current) {
                continue;
            }

            callback(newGlobalState, true);
        }
    }

    return [internalState, (newState: EventCallbackInput<T>) => updateGlobalState(newState, false)] as const;
}
import IndexedDBHelper from "./indexedDBHelper";

export interface RequestOptions {
    headers?: {[key: string]: string};
    body?: any;
    method?: "POST" | "GET" | "PUT" | "DELETE";
}

export interface CachedRequestOptions {
    headers?: {[key: string]: string};
    body?: any;
    method?: "POST" | "GET" | "PUT" | "DELETE";
    /** Time in ms for the cache entry to stay valid, default is 30 seconds */
    ttl?: number;
}

export interface FormattedResponse {
    statusCode: number;
    body: any;
}

export interface CachedResponse {
    date: Date;
    result: FormattedResponse;
}

export default class RequestHelper{
    public static port: number = (window.location.protocol.startsWith("https") ? 443 : 80);
    public static readonly API_URL = process.env.NODE_ENV === 'development' ? `${window.location.protocol}//api.${window.location.hostname}:8181` : `${window.location.protocol}//api.${window.location.hostname}`;
    private static inProgress: Map<String, Promise<FormattedResponse>> = new Map();
    private static cache = new IndexedDBHelper<CachedResponse>('requestCache');

    static async request(url: string, opts?: RequestOptions): Promise<any>{
        try{
            let response = this.formattedRequest(url, opts);
            return (await response).body;
        }catch(err: any){
            console.error(err);
            return {message: err.message};
        }
    }

    static async chachedRequest(url: string, opts?: CachedRequestOptions): Promise<FormattedResponse>{
        const inProgressRequest = this.inProgress.get(url);
        if (inProgressRequest){
            return inProgressRequest;
        }

        // Check the cache, if present and valid return that instead
        const cached = await RequestHelper.cache.getItem(url);
        const isValid = cached && cached.date.getTime() < Date.now() - (opts?.ttl ?? 30000);
        if (isValid){
            return cached.result;
        } else if (cached){
            await RequestHelper.cache.deleteItem(url);
        }

        // Store a copy in the currently in progress requests, 
        // so if another is requested rapidly it'll be reused.
        const req = this.formattedRequest(url, opts);
        this.inProgress.set(url, req);

        // Once the request is completed, remove from in progress
        req.then((result) => {
            this.inProgress.delete(url);
            RequestHelper.cache.setItem(url, {date: new Date(), result});
        });

        

        return req;
    }

    static async formattedRequest(url: string, opts?: RequestOptions): Promise<FormattedResponse>{
        // console.log(window.location)
        if (!url.startsWith("/")){
            url = "/" + url;
        }
        if (!url.startsWith(this.API_URL)){
            url = this.API_URL + url;
        }

        if (!opts){
            opts = {};
        }
        if (!opts.headers){
            opts.headers = {}
        }
        // if (!opts.headers["authorization"] && useToken && SessionHelper.tokenString){
        //     opts.headers["authorization"] = SessionHelper.tokenString;
        // }

        if (opts.body){
            opts.method = opts.method ?? "POST";
        }

        opts.headers["Content-type"] = opts.headers["Content-type"] ?? "application/json";
        opts.headers["Accepts"] = opts.headers["Accepts"] ?? "application/json";

        if (opts.body && typeof opts.body !== "string"){
            opts.body = JSON.stringify(opts.body);
        }

        let response = await fetch(url, {...opts, 'credentials':'include'});

        // console.log(JSON.stringify(response));
        let vals: string | any = await response.text();
        try{
            vals = JSON.parse(vals);
        }catch (err){
            // vals = await response.text();
        }

        return {
            statusCode: response.status,
            body: vals,
        }
    }
}
export default class IndexedDBHelper<T = unknown> {
    private dbInstance: Promise<IDBDatabase>;
    private dbName: string;

    constructor(dbName: string){
        this.dbName = dbName;
        const db = indexedDB.open(dbName);
        this.dbInstance = new Promise<IDBDatabase>((res, rej) => {
            db.addEventListener('success', (_ev) => {
                res(db.result);
                if (!db.result.objectStoreNames.contains(dbName)){
                    db.result.createObjectStore(dbName, {keyPath: 'key'});
                }
            });

            db.addEventListener('error', (_ev) => {
                rej('Failed to open db');
            });

            db.addEventListener('upgradeneeded', (_ev) => {
                db.result.createObjectStore(dbName, {keyPath: 'key'});
            });
        });
    }

    async getItem(key: string): Promise<T>{
        const tx = (await this.dbInstance).transaction(this.dbName, 'readonly')
        const os = tx.objectStore(this.dbName);
        const request = os.get(key);
        
        const output = new Promise<T>((res, rej) => {
            request.onsuccess = ((_ev) => {
                res(request.result?.item);
            });

            request.onerror = () => rej('Failed to get item');
        });

        tx.commit();

        return output;
    }

    async getAllItems(): Promise<T[]>{
        const tx = (await this.dbInstance).transaction(this.dbName, 'readonly')
        const os = tx.objectStore(this.dbName);
        const request = os.getAll();
        
        
        const output = new Promise<T[]>((res, rej) => {
            request.onsuccess = ((_ev) => {
                res(request.result);
            });

            request.onerror = () => rej('Failed to get items');
        });

        tx.commit();

        return output;
    }

    async deleteItem(key: string): Promise<boolean>{
        const tx = (await this.dbInstance).transaction(this.dbName, 'readwrite')
        const os = tx.objectStore(this.dbName);
        const request = os.delete(key);
        
        const output = new Promise<boolean>((res) => {
            request.onsuccess = ((_ev) => {
                res(true);
            });

            request.onerror = () => res(false);
        });

        tx.commit();

        return output;
    }

    async setItem(key: string, item: T): Promise<boolean>{
        const tx = (await this.dbInstance).transaction(this.dbName, 'readwrite')
        const os = tx.objectStore(this.dbName);
        const request = os.add({key, item});

        const output = new Promise<boolean>((res) => {
            request.addEventListener('success', () => {
                res(true);
            });

            request.addEventListener('error', () => {
                res(false);
            });
        });

        tx.commit();

        return output;
    }


}
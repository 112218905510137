import RequestHelper from "helpers/requestHelper";

export default class ModelService<I>{
    private modelName: string;
    constructor(modelName: string) {
        this.modelName = modelName;
        this.create = this.create.bind(this);
        this.read = this.read.bind(this);
        this.update = this.update.bind(this);
        this.delete = this.delete.bind(this);
        this.wipe = this.wipe.bind(this);
    }

    /**
     * Fetch a set of items by matching parameters with query
     * @param query 
     * @returns Array of items that match the query
     * @throws if the API returns a non-200 statusCode
     */
    public async read(query: Partial<I>): Promise<I[]> {

        const queryParams: string[] = [];

        for (const key of Object.keys(query)) {
            queryParams.push(`${key}=${(query as any)[key]}`);
        }

        let response = await RequestHelper.formattedRequest(`/data/${this.modelName}?${queryParams.join('&')}`);
        if (response.statusCode === 200 && Array.isArray(response.body)) {
            return response.body;
        }

        throw Error(response.body);
    }

    /**
     * 
     * @param item 
     * @returns Array with item that's been created
     * @throws If the API returns a statusCode other than 200
     */
    public async create(item: I): Promise<I[]> {
        let response = await RequestHelper.formattedRequest(`/data/${this.modelName}`, {
            method: 'POST',
            body: item
        });

        if (response.statusCode === 200 && Array.isArray(response.body)) {
            return response.body;
        }

        throw Error(response.body);
    }

    /**
     * 
     * @param item Item to be updated
     * @param id ID of the item to update
     * @returns Array with the updated item
     * @throws IF the API returns a statusCode other than 200
     */
    public async update(item: Partial<I>, id: string): Promise<I[]> {
        let response = await RequestHelper.formattedRequest(`/data/${this.modelName}/${id}`, {
            method: 'PUT',
            body: item
        });

        if (response.statusCode === 200 && Array.isArray(response.body)) {
            return response.body;
        }

        throw Error(response.body);
    }

    /**
     * 
     * @param query Conditions for the item to be deleted, can be empty if not needed
     * @param id ID of the item to delete
     * @returns Array with the deleted item
     * @throws IF the API returns a statusCode other than 200
     */
    public async delete(query: Partial<I>, id: string): Promise<I[]> {
        let response = await RequestHelper.formattedRequest(`/data/${this.modelName}/${id}`, {
            method: 'DELETE',
            body: query,
        });

        if (response.statusCode === 200 && Array.isArray(response.body)) {
            return response.body;
        }

        throw Error(response.body);
    }

    /**
     * USE CAREFULLY!!!!
     * This will send a request to delete all data associated with the current user.
     * @returns All items deleted
     */
    public async wipe(): Promise<I[]>{
        let response = await RequestHelper.formattedRequest(`/wipe/data/${this.modelName}`, {
            method: 'DELETE'
        });

        if (response.statusCode === 200 && Array.isArray(response.body)){
            return response.body;
        }

        throw Error(response.body);
    }


}
import { Autosuggest, AutosuggestProps } from "@cloudscape-design/components";
import { DropdownStatusProps } from "@cloudscape-design/components/internal/components/dropdown-status";
import { OptionsLoadItemsDetail } from "@cloudscape-design/components/internal/components/dropdown/interfaces";
import ModelService from "data/modelService";
import { SearchableModel } from "data/search/searchableModel";
import { useState } from "react";

const searchService = new ModelService<SearchableModel>('Searchable');

export default function GlobalSearch() {
    const [searchText, setSearchText] = useState('');
    const [status, setStatus] = useState<DropdownStatusProps.StatusType>()
    const [options, setOptions] = useState<AutosuggestProps.Options>([]);

    async function onLoadItems(detail: OptionsLoadItemsDetail) {
        if (detail.filteringText.trim().length === 0) {
            return;
        }
        setStatus('loading');
        try {
            const results = await searchService.read({ searchableText: detail.filteringText });

            const newOptions: AutosuggestProps.OptionGroup[] = [];

            const resultsMap = new Map<string, AutosuggestProps.Option[]>();

            for (const result of results) {
                if (!resultsMap.has(result.group)) {
                    resultsMap.set(result.group, []);
                }

                resultsMap.get(result.group)?.push({
                    label: result.label,
                    value: result.link,
                    description: result.link
                });
            }

            for (const type of Array.from(resultsMap.keys())) {
                const newOption = {
                    label: type,
                    options: resultsMap.get(type),
                } as AutosuggestProps.OptionGroup;
                newOptions.push(newOption);
            }
            
            setOptions(newOptions);
            setStatus('finished');
        } catch (err) {
            setStatus('error');
        }

    }

    function onSelect(value: string){
        if (value === searchText){
            return;
        }

        window.location.hash = value;
    }

    return (
        <Autosuggest
            placeholder='Search...'
            enteredTextLabel={(value: string) => value}
            value={searchText}
            options={options}
            onSelect={({detail}) => onSelect(detail.value)}
            onLoadItems={({ detail }) => onLoadItems(detail)}
            onChange={({ detail }) => setSearchText(detail.value)}
            statusType={status}
            errorText='Something went wrong'
            recoveryText='Retry'
            loadingText='Searching...'
            empty='No search results'
            expandToViewport
            virtualScroll={options.length > 50}
        />
    );
}
import { Box, Button, Modal, SpaceBetween } from "@cloudscape-design/components";
import { useState } from "react";


export async function asyncConfirm(prompt: string): Promise<boolean> {
    if (!confirmRef.setConfirm){
        return false;
    }

    // Handle duplicate calls
    while (confirmRef.confirmPromise){
        // Wait for the other promise to resolve
        await confirmRef.confirmPromise;
    }

    confirmRef.confirmPromise = new Promise<boolean>((res) => {
        confirmRef.resolveConfirm = (v) => {
            res(v);

            // Clear the prompt after it's resolved
            confirmRef.resolveConfirm = null;
            confirmRef.confirmPromise = null;
        };
    });

    confirmRef.setConfirm(prompt);

    return confirmRef.confirmPromise;
}

interface ConfirmRefInf {
    setConfirm: React.Dispatch<React.SetStateAction<string | undefined>> | null;
    confirmPromise: Promise<boolean> | null;
    resolveConfirm: ((val: boolean) => void) | null;
}

const confirmRef: ConfirmRefInf = {
    setConfirm: null,
    confirmPromise: null,
    resolveConfirm: null
}

export default function ConfirmModal() {
    const [prompt, setPrompt] = useState<string>();
    
    confirmRef.setConfirm = setPrompt;

    return (
        <Modal header={prompt} visible={!!prompt} onDismiss={() => {
            if (confirmRef.resolveConfirm){
                confirmRef.resolveConfirm(false);
            }
            confirmRef.confirmPromise = null;
            setPrompt(undefined);
        }} footer={<Box float="right">
            <SpaceBetween size='s' direction="horizontal">
                <Button onClick={() => {
                    if (confirmRef.resolveConfirm){
                        confirmRef.resolveConfirm(false);
                    }
                    confirmRef.confirmPromise = null;
                    setPrompt(undefined);
                }}>Cancel</Button>
                <Button onClick={() => {
                    if (confirmRef.resolveConfirm){
                        confirmRef.resolveConfirm(true);
                    }
                    confirmRef.confirmPromise = null;
                    setPrompt(undefined);
                }}>Ok</Button>
            </SpaceBetween>
        </Box>}/>
    )
}
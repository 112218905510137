import React, { useState } from 'react';
import { Alert, Button, Container, FormField, Header, Input, Link, Spinner } from "@cloudscape-design/components";
import { AccountHelper } from 'helpers/accountHelper';
import { FormattedResponse } from 'helpers/requestHelper';

export default function LoginView(): JSX.Element {
    const [isLoading, setIsLoading] = useState(false);
    const [username, setUsername] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [passwordConfirmation, setPasswordConfirmation] = useState<string>('');
    const [errorText, setErrorText] = useState<string>();
    const [successText, setSuccessText] = useState<string>();
    const [loginType, setLoginType] = useState<'login' | 'signup'>('login');


    async function onConfirmPressed() {
        if (isLoading) {
            return;
        }
        setIsLoading(true);
        setErrorText(undefined);

        try {
            let response: FormattedResponse;

            if (loginType === 'login') {
                response = await AccountHelper.login(username, password);
            } else {
                response = await AccountHelper.signup(username, password, name);
            }
            console.log(response);

            if (response.statusCode !== 200) {
                setErrorText(response.body);
                setSuccessText(undefined);
                return;
            }

            // If the account was successfully requested, show the message instead of reloading.
            if (response.statusCode === 200 && loginType === 'signup'){
                setSuccessText(response.body);
                setErrorText(undefined);
                return;
            } else {
                window.location.reload();
            }

        } catch (err: any) {
            if ('message' in err) {
                setErrorText(`Error: ${err.message}`);
            }
        } finally {
            setIsLoading(false);
        }
    }

    function getFooter() {
        const label = loginType === 'login' ? 'Login' : 'Signup';

        const isDisabled = isLoading || password.trim().length === 0 || (loginType === 'signup' && password !== passwordConfirmation);

        return (
            <div style={{ justifyContent: 'end', width: '100%', display: 'flex' }}>
                <Button
                    variant='primary'
                    onClick={onConfirmPressed}
                    disabled={isDisabled}
                    ariaLabel={label}
                >{isLoading ? <Spinner /> : label}</Button>
            </div>
        )
    }

    function getToggleButton() {

        if (loginType === 'signup') {
            return (
                <Link ariaLabel='switch to login' onFollow={() => setLoginType('login')}>Switch to Login</Link>
            )
        }

        return <Link ariaLabel='switch to signup' onFollow={() => setLoginType('signup')}>Switch to Signup</Link>;
    }

    function getUsernamePasswordEntry() {
        return (
            <>
                <FormField label="Username">
                    <Input
                        type='email'
                        value={username}
                        ariaLabel='username input'
                        onChange={({ detail }) => setUsername(detail.value.trim())}
                    />
                </FormField>
                <FormField label="Password">
                    <Input
                        type='password'
                        value={password}
                        ariaLabel='password input'
                        onChange={({ detail }) => setPassword(detail.value.trim())}
                    />
                </FormField>
            </>
        )
    }

    function getLogin() {
        return (
            <Container header={<Header description={getToggleButton()}>Login</Header>} footer={getFooter()}>
                {
                    getUsernamePasswordEntry()
                }
            </Container>
        )
    }

    function getSignup() {
        let confirmationError;

        if (password.length > 0 && passwordConfirmation.length > 0 && password !== passwordConfirmation) {
            confirmationError = 'Passwords do not match';
        }

        return (
            <Container header={<Header description={getToggleButton()}>Signup</Header>} footer={getFooter()}>
                {
                    getUsernamePasswordEntry()
                }
                <FormField label="Confirm Password" errorText={confirmationError}>
                    <Input
                        type='password'
                        value={passwordConfirmation}
                        ariaLabel='password confirmation input'
                        onChange={({ detail }) => setPasswordConfirmation(detail.value.trim())}
                    />
                </FormField>
                <FormField label="Nickname" description='Visible to others'>
                    <Input
                        type='text'
                        value={name}
                        ariaLabel='nickname input'
                        onChange={({ detail }) => setName(detail.value)}
                    />
                </FormField>
            </Container>
        )
    }

    return (
        <>
            {
                errorText
                    ? <Alert type='error' dismissAriaLabel='dismiss error message' onDismiss={() => setErrorText(undefined)} dismissible>{errorText}</Alert>
                    : null
            }
            {
                successText
                    ? <Alert type='success' dismissAriaLabel='dismiss success message' onDismiss={() => setSuccessText(undefined)} dismissible>{successText}</Alert>
                    : null
            }
            <div style={{
                height: '100%',
                alignContent: 'center',
                justifyContent: 'center',
                display: 'flex'
            }}>
                <div style={{
                    display: 'flex',
                    alignContent: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                    // marginTop: '12.5%',
                }}>
                    <div style={{ display: 'inline' }}>
                        {
                            loginType === 'login'
                                ? getLogin()
                                : getSignup()
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
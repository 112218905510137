import { Box, Button, Input, InputProps, Modal, SpaceBetween } from "@cloudscape-design/components";
import { useState } from "react";

export interface AsyncPromptOptions {
    prompt: React.ReactNode;
    inputProps?: Omit<InputProps, "value" | "onChange">;
}

export async function asyncPrompt(options?: AsyncPromptOptions): Promise<string | null> {
    if (!promptRef.setPrompt){
        return null;
    }

    // Handle duplicate calls
    while (promptRef.promptPromise){
        // Wait for the other to resolve
        await promptRef.promptPromise;
    }

    promptRef.promptPromise = new Promise<string | null>((res) => {
        promptRef.resolvePrompt = (v) => {
            res(v);

            // Clear the prompt after it's resolved;
            promptRef.resolvePrompt = null;
            promptRef.promptPromise = null;
        };
    });

    promptRef.setPrompt(options);

    return promptRef.promptPromise;
}

type PromptInputType = AsyncPromptOptions | null | undefined;

interface PromptRefInf {
    setPrompt: React.Dispatch<React.SetStateAction<PromptInputType>> | null;
    promptPromise: Promise<string | null> | null;
    resolvePrompt: ((val: string | null) => void) | null;
}

const promptRef: PromptRefInf = {
    setPrompt: null,
    promptPromise: null,
    resolvePrompt: null
}

export default function PromptModal() {
    const [inputText, setInputText] = useState<string | null>(null);
    const [prompt, setPrompt] = useState<PromptInputType>();
    
    promptRef.setPrompt = setPrompt;

    return (
        <Modal header={prompt?.prompt} visible={!!prompt} onDismiss={() => {
            if (promptRef.resolvePrompt){
                promptRef.resolvePrompt(null);
            }
            promptRef.promptPromise = null;
            setPrompt(undefined);
        }} footer={<Box float="right">
            <SpaceBetween size='s' direction="horizontal">
                <Button onClick={() => {
                    if (promptRef.resolvePrompt){
                        promptRef.resolvePrompt(null);
                    }
                    promptRef.promptPromise = null;
                    setInputText(null);
                    setPrompt(undefined);
                }}>Cancel</Button>
                <Button onClick={() => {
                    if (promptRef.resolvePrompt){
                        promptRef.resolvePrompt(inputText);
                    }
                    promptRef.promptPromise = null;
                    setInputText(null);
                    setPrompt(undefined);
                }}>Ok</Button>
            </SpaceBetween>
        </Box>}>
            <Input {...prompt?.inputProps} value={inputText ?? ''} onChange={({detail}) => {
                if (!detail.value){
                    setInputText(null);
                    return;
                }
                setInputText(detail.value);
            }}></Input>
        </Modal>
    )
}
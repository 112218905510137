import React, { Dispatch, ReactElement, ReactNode, SetStateAction, Suspense, createRef, useEffect, useState } from 'react';
import { AppLayout, SpaceBetween, Spinner, TextContent, } from '@cloudscape-design/components';
import GlobalHeader from './layout/GlobalHeader';
import LoginView from './views/login/LoginView';
import { AccountHelper } from 'helpers/accountHelper';
import PromptModal from 'components/PromptModal';
import ConfirmModal from 'components/ConfirmModal';
import GlobalBreadcrumbs from './layout/GlobalBreadcrumbs';
import { applyMode, Mode, applyDensity, Density } from '@cloudscape-design/global-styles';
import { UseSplitPanelResponse } from 'hooks/useSplitPanelResponse';
const MainRouting = React.lazy(() => import('./MainRouting'));

export const splitPanelRef: UseSplitPanelResponse = {
    setSplitPanel: (val: any) => {},
    splitPanelOpen: false,
    setSplitPanelOpen: (val: any) => {},
};

export default function App(): JSX.Element {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [splitPanel, setSplitPanel] = useState<ReactNode | undefined>();
    const [splitPanelOpen, setSplitPanelOpen] = useState(false);

    if (splitPanelRef){
        splitPanelRef.splitPanel = splitPanel;
        splitPanelRef.setSplitPanel = setSplitPanel;
        splitPanelRef.splitPanelOpen = splitPanelOpen;
        splitPanelRef.setSplitPanelOpen = setSplitPanelOpen;
    }

    useEffect(() => {
        applyMode(localStorage.getItem('theme') === Mode.Dark ? Mode.Dark : Mode.Light);
        applyDensity(localStorage.getItem('density') === Density.Compact ? Density.Compact : Density.Comfortable);

        /**
         * Renew the account once reconnected.
         */
        function onNetworkConnect() {
            AccountHelper.renew().catch((err) => {
                console.error(err);
            });
        }
        window.addEventListener('online', onNetworkConnect);

        // In offline mode, treat user as logged in if they have a token
        if (!navigator.onLine && AccountHelper.token()) {
            setIsLoading(false);
            setIsLoggedIn(true);
            return;
        }

        AccountHelper.renew().then((response) => {
            setIsLoading(false);
            setIsLoggedIn(response.statusCode === 200);
        });
    }, []);

    function getContent() {
        if (isLoading) {
            return (
                <SpaceBetween direction='horizontal' size='m'>
                    <Spinner />
                    <TextContent>Loading...</TextContent>
                </SpaceBetween>
            )
        }

        if (!isLoggedIn) {
            return <LoginView />
        }

        return (
            <Suspense fallback={<SpaceBetween size='m' direction='horizontal'><Spinner /><TextContent>Loading...</TextContent></SpaceBetween>}>
                <MainRouting />
            </Suspense>
        )
    }

    return (
        <>
            <PromptModal />
            <ConfirmModal />
            <GlobalHeader isLoggedIn={isLoggedIn} />
            <AppLayout
                ariaLabels={{
                    navigation: 'navigation',
                    tools: 'tools',
                }}
                breadcrumbs={isLoggedIn ? <GlobalBreadcrumbs /> : undefined}
                headerSelector='.header'
                content={
                    getContent()
                }
                splitPanel={splitPanel}
                splitPanelOpen={splitPanelOpen}
                onSplitPanelToggle={({ detail }) => {
                    setSplitPanelOpen(detail.open);
                }}
                splitPanelPreferences={{
                    position: 'side',
                }}
                toolsHide
                navigationHide
            />
        </>
    );
}

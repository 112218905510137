import { BreadcrumbGroup, BreadcrumbGroupProps } from "@cloudscape-design/components";
import keyToHeader from "common/keyToHeader";
import useGlobalState from "hooks/useGlobalState";
import { useCallback, useEffect, useState } from "react";

export default function GlobalBreadcrumbs() {
    const [replacements] = useGlobalState<Record<string, string>>('replacements', { '#': 'Home' });
    const [items, setItems] = useState<BreadcrumbGroupProps.Item[]>(getBreadcrumbs());

    const loadBreadcrumbs = useCallback(() => {
        let breadcrumbs = getBreadcrumbs();
        document.title = `OneNOP${breadcrumbs.length > 0 ? ' - ' : ''}${breadcrumbs.filter(_ => _.text !== 'Home').map(_ => _.text).join(' - ')}`
        setItems(breadcrumbs);
    }, [replacements]);

    useEffect(() => {
        loadBreadcrumbs();
    }, [replacements]);

    useEffect(() => {
        window.addEventListener('hashchange', loadBreadcrumbs);
        return () => window.removeEventListener('hashchange', loadBreadcrumbs);
    }, [replacements]);

    function getBreadcrumbs(): BreadcrumbGroupProps.Item[] {
        let output: BreadcrumbGroupProps.Item[] = [];
        let partsToCurrent: string[] = [];

        const hashNoTrailing = window.location.hash.replace(/[/]$/, '').replace(/\?.*/, '');

        for (const part of hashNoTrailing.split('/')) {
            if (part.length === 0){
                continue;
            }
            let header = keyToHeader(part);
            partsToCurrent.push(part);

            if (header in replacements) {
                header = replacements[header];
            }

            output.push({
                text: header,
                href: `${partsToCurrent.join('/')}`
            });
        }

        return output.length > 1 ? output : [];
    }

    return (
        <BreadcrumbGroup
            items={items}
        />
    )
}
import RequestHelper, { FormattedResponse } from "./requestHelper";

export interface TokenInf {
    username: string;
    nickname: string;
    iat?: number;
    exp?: number;
}


export const AccountHelper = {
    TOKEN_KEY: 'token',
    token(): TokenInf | null{
        const tokenStr = localStorage.getItem(AccountHelper.TOKEN_KEY);
        if (!tokenStr){
            return null;
        }
        const token = tokenStr.split('.')[1];
        return JSON.parse(atob(token));
    },
    logout() {
        RequestHelper.formattedRequest('/account/logout').then(() => {
            localStorage.clear();
            window.location.reload();
        });
    },
    async login(username: string, password: string) {
        const response =  await RequestHelper.formattedRequest('/account/login', {
            body: {
                username: username,
                password: password
            },
            method: 'POST',
        });

        // API returns the token
        if (response.statusCode === 200){
            localStorage.setItem(AccountHelper.TOKEN_KEY, response.body);
        }

        return response;
    },
    async signup(username: string, password: string, nickname: string){
        const response =  await RequestHelper.formattedRequest('/account/signup', {
            body: {
                username,
                password,
                nickname,
            },
            method: 'POST',
        });

        if (response.statusCode === 200){
            localStorage.setItem(AccountHelper.TOKEN_KEY, response.body);
        }

        return response;
    },
    async approve(username: string){
        const response =  await RequestHelper.formattedRequest('/account/approve', {
            body: {
                username,
            },
            method: 'POST',
        });

        return response;
    },
    async renew(){
        // Don't try to renew without a token
        if (!this.token()){
            return {statusCode: 500};
        }
        const response = await RequestHelper.formattedRequest('/account/renew');

        if (response.statusCode === 200){
            localStorage.setItem(AccountHelper.TOKEN_KEY, response.body);
        }

        return response;
    }, 
    /**
     * BECAREFUL! DON'T USE THIS OTHER THAN MyDataView!!!
     * @returns Response from the account delete api
     */
    async DELETEACCOUNT(){
        if (!this.token()){
            return {statusCode: 500, body: 'No Token'} as FormattedResponse;
        }
        return await RequestHelper.formattedRequest('/account/delete', {method: 'DELETE'});
    }
} as const;
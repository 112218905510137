import { ButtonDropdownProps, TopNavigation, TopNavigationProps } from "@cloudscape-design/components";
import { Density, Mode, applyDensity, applyMode } from '@cloudscape-design/global-styles';
import { AccountHelper } from "helpers/accountHelper";
import GlobalSearch from "./GlobalSearch";

export interface GlobalHeaderProps {
    isLoggedIn: boolean;
}

export default function GlobalHeader(props: GlobalHeaderProps): JSX.Element {
    const actions: { [key: string]: () => any } = {
        'logout': AccountHelper.logout,
        'theme': toggleTheme,
        'density': toggleDensity,
    }

    function onActionSelected(event: CustomEvent<ButtonDropdownProps.ItemClickDetails>) {
        if (event.detail.id in actions) {
            actions[event.detail.id]();
        }
    }

    function toggleTheme() {
        const theme = localStorage.getItem('theme');
        const newMode = theme === Mode.Light ? Mode.Dark : Mode.Light;

        applyMode(newMode);
        localStorage.setItem('theme', newMode);
    }

    function toggleDensity() {
        const density = localStorage.getItem('density');
        // New density is opposite of current.
        const newDensity = density === Density.Compact ? Density.Comfortable : Density.Compact;
        applyDensity(newDensity);
        localStorage.setItem('density', newDensity);
    }

    function getMyAccountDropdownText(){
        const nickname = AccountHelper.token()?.nickname;
        if (!nickname){
            return 'My Account';
        }

        if (nickname.trim().length === 0){
            return 'My Account';
        }

        return nickname;
    }

    return (
        <div className="header" style={{ position: 'sticky', top: 0, zIndex: 1002 }}>
            <TopNavigation
                identity={{
                    href: '#/',
                    title: 'OneNOP',
                    logo: {
                        src: 'logo192.png',
                    }
                }}
                utilities={props.isLoggedIn ? ([
                    (
                        AccountHelper.token() ?
                            {
                                type: 'menu-dropdown',
                                ariaLabel: 'account dropdown',
                                onItemClick: onActionSelected,
                                text: getMyAccountDropdownText(),
                                iconName: 'user-profile',
                                items: [
                                    {
                                        text: `Account - ${AccountHelper.token()?.username}`,
                                        externalIconAriaLabel: 'Account',
                                        items: [
                                            { text: 'Manage My Profile', href: '#/my-profile' },
                                            { text: 'Manage My Data', href: '#/my-data'},
                                            { text: 'Logout', id: 'logout', disabled: !AccountHelper.token() }
                                        ]
                                    }
                                ]
                            }
                            : undefined
                    ),
                    {
                        iconName: 'settings',
                        ariaLabel: 'settings dropdown',
                        type: 'menu-dropdown',
                        onItemClick: onActionSelected,
                        items: [
                            {
                                text: 'Settings',
                                externalIconAriaLabel: 'Settings',
                                items: [
                                    { text: 'Toggle Dark/Light Mode', id: 'theme' },
                                    { text: 'Toggle Content Density', id: 'density' },
                                ],
                            },
                        ]
                    },
                ] as (TopNavigationProps.Utility)[]).filter(_ => _) : undefined}
                search={props.isLoggedIn ? <GlobalSearch /> : undefined}
                i18nStrings={{
                    searchIconAriaLabel: "Search",
                    searchDismissIconAriaLabel: "Close search",
                    overflowMenuTriggerText: "More",
                    overflowMenuTitleText: "All",
                    overflowMenuBackIconAriaLabel: "Back",
                    overflowMenuDismissIconAriaLabel: "Close menu",
                }}
            />
        </div>
    );
}
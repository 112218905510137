function camelToTitleCase(text: string) {
    const result = text.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
}


/**
 * Convert snake_case and camelCase keys into Title Case.
 * @param key Convert a key to a header
 * @returns key value in Title Case
 */
export default function keyToHeader(key: string): string {
    if (key.includes('_')){
        return key.split("_").map((k) => (k[0] ?? '').toUpperCase() + (k ?? '').slice(1)).join(" ");
    }

    if (key.includes('-')){
        return key.split("-").map((k) => (k[0] ?? '').toUpperCase() + (k ?? '').slice(1)).join(" ");
    }
    
    return camelToTitleCase(key);
}